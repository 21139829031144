import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import { FilePicker } from "react-file-picker";
import { CSVReader } from "react-papaparse";

const uuidv4 = require("uuid/v4");

const sku_mapping = {
  orboot: "shifu014",
  orboot_dinos: "shifu027",
  orboot_mars: "shifu028",
  stemwizpack: "shifu026",
  plugo_piano: "shifu022WG",
  piano: "shifu022",
  plugo_link: "shifu019WG",
  link: "shifu019",
  plugo_count: "shifu020WG",
  count: "shifu020",
  plugo_letters: "shifu025WG",
  letters: "shifu025",
  plugo_slingshot: "shifu023WG",
  slingshot: "shifu023",
  plugo_gamepad: "shifu034",
};
const price_mapping = {
  shifu014: 50.0,
  shifu027: 50.0,
  shifu026: 80.0,
  shifu022WG: 35.0,
  shifu022: 50.0,
  shifu019WG: 35.0,
  shifu019: 50.0,
  shifu020WG: 35.0,
  shifu020: 50.0,
  shifu025WG: 35.0,
  shifu025: 50.0,
  shifu023WG: 35.0,
  shifu023: 50.0,
  shifu034: 35.0,
};
const dp = {};

let data = [{}];
export default class ManualOrder extends Component {
  state = {
    referenceNumber: "",
    orderType: "Consumer",
    customerCompanyName: "Customer Inc.",
    customerFullName: "",
    customerStreetAddress1: "",
    customerStreetAddress2: "",
    customerStreetAddress3: "",
    customerCity: "",
    customerStateOrProvince: "",
    customerPostalCode: "",
    customerCountry: "",
    customerEmailAddress: "",
    customerTelephoneNumber: "",
    orderShippingMethod: "UPS Ground",
    orderSubTotalAmount: "",
    orderTaxAmount: "",
    orderShippingCostAmount: "",
    orderTotalAmount: "",
    stockKeepingUnits: [],
    selectedSKU: "",
    selectedQuantity: "",
    selectedArrayString: "",
    authToken: "",
    totalAmount: 0,
    bulkOrders: [],
  };

  componentDidMount() {
    this.getAuthToken();
  }
  selectedSKU = (e) => {
    e.preventDefault();
    this.setState({ selectedSKU: e.target.value });
    console.log(this.state.selectedSKU, this.state.selectedQuantity);
  };
  selectedQuantity = (e) => {
    e.preventDefault();
    this.setState({ selectedQuantity: e.target.value });
    console.log(this.state.selectedSKU, this.state.selectedQuantity);
  };

  getAuthToken = () => {
    fetch(`https://api.shipnetwork.com/Auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        clientId: "5004",
        apiUserIdentifier: "455575fb-86a9-413d-ade3-ebf2a9d527f2",
        apiUserSecret:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NDU3NjkxNzMsImlzcyI6IlJha3V0ZW5TdXBlckxvZ2lzdGljcy5TZWN1cml0eSIsImF1ZCI6IlJha3V0ZW5TdXBlckxvZ2lzdGljcy5TZWN1cml0eSJ9.j5gCrUqdh8pddCQR7LRb1fvk9oeNH5aY_WrFsX2csmM",
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({ authToken: result.data.token });
        // this.props.getRakutenItems(result.data.token);
        // this.props.getRakutenOrders(result.data.token);
        console.log("FETCH RESPONSE AUTH TOKEN", result);
      })
      .catch((err) => console.log("FETCH ERROR", err));
  };

  onPlaceOrder = (e) => {
    e.preventDefault();

    let order = {
      referenceNumber: uuidv4().replace(/-/g, ""),
      orderType: "Consumer",
      customerCompanyName: "Test Inc.",
      customerFullName: this.state.customerFullName,
      customerStreetAddress1: this.state.customerStreetAddress1,
      customerStreetAddress2: "",
      customerStreetAddress3: "",
      customerCity: this.state.customerCity,
      customerStateOrProvince: this.state.customerStateOrProvince,
      customerPostalCode: this.state.customerPostalCode,
      customerCountry: this.state.customerCountry,
      customerEmailAddress: this.state.customerEmailAddress,
      customerTelephoneNumber: this.state.customerTelephoneNumber,
      orderShippingMethod: "UPS Ground",
      orderComments: "Fragile. This side up.",
      orderSubTotalAmount: this.state.totalAmount,
      orderTaxAmount: 0.0,
      orderShippingCostAmount: 0.0,
      orderTotalAmount: this.state.totalAmount,
      // stockKeepingUnits: [
      //   {
      //     stockKeepingUnit: "shifu014-SMC",
      //     quantityOrdered: 1,
      //     unitPrice: 50.0,
      //   },
      // ],
      stockKeepingUnits: this.state.stockKeepingUnits,
    };
    console.log("ORDER", order);
    fetch(`https://api.shipnetwork.com/Orders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        // type: "no-cors",
        Authorization: `Bearer ${this.state.authToken}`,
        ClientId: "5004",
      },
      body: JSON.stringify(order),
    })
      .then((response) => {
        console.log("RESPONSE", response);
        if (response.status == 202) alert("Order placed successfully");
        else alert("Failed : Please try again");
        this.setState({
          referenceNumber: "",
          orderType: "Consumer",
          customerCompanyName: "Customer Inc.",
          customerFullName: "",
          customerStreetAddress1: "",
          customerStreetAddress2: "",
          customerStreetAddress3: "",
          customerCity: "",
          customerStateOrProvince: "",
          customerPostalCode: "",
          customerCountry: "",
          customerEmailAddress: "",
          customerTelephoneNumber: "",
          orderShippingMethod: "UPS Ground",
          orderSubTotalAmount: "",
          orderTaxAmount: "",
          orderShippingCostAmount: "",
          orderTotalAmount: "",
          stockKeepingUnits: [],
          selectedSKU: "",
          selectedQuantity: "",
          selectedArrayString: "",
          authToken: "",
          totalAmount: 0,
        });
      })
      .then((result) => {
        // response.json();
        console.log("RESULT", result);
      })
      .catch((err) => console.log("FETCH ERROR", err));
  };
  placeOrderBulk = (e) => {
    e.preventDefault();
    this.state.bulkOrders.length > 0 &&
      this.state.bulkOrders.forEach((element) => {
        let order = element;
        console.log("ORDER", order);
        fetch(`https://api.shipnetwork.com/Orders`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            // type: "no-cors",
            Authorization: `Bearer ${this.state.authToken}`,
            ClientId: "5004",
          },
          body: JSON.stringify(order),
        })
          .then((response) => {
            console.log("RESPONSE", response);
            // if (response.status == 202) alert("Order placed successfully");
            // else alert("Failed : Please try again");
          })
          .then((result) => {
            // response.json();
            console.log("RESULT", result);
          })
          .catch((err) => console.log("FETCH ERROR", err));
      });
  };
  addButtonClicked = (e) => {
    e.preventDefault();
    this.setState({
      stockKeepingUnits: [
        ...this.state.stockKeepingUnits,
        {
          stockKeepingUnit: sku_mapping[this.state.selectedSKU],
          // stockKeepingUnit: "shifu014-SMC",
          quantityOrdered: this.state.selectedQuantity,
          unitPrice: price_mapping[sku_mapping[this.state.selectedSKU]],
        },
      ],

      totalAmount: this.state.totalAmount + price_mapping[sku_mapping[this.state.selectedSKU]],
    });

    if (this.state.selectedArrayString === "")
      this.setState({
        selectedArrayString: `${this.state.selectedSKU}:${this.state.selectedQuantity}`,
      });
    else
      this.setState({
        selectedArrayString:
          this.state.selectedArrayString + `,${this.state.selectedSKU}:${this.state.selectedQuantity}`,
      });

    console.log(this.state.selectedSKU, this.state.selectedQuantity, this.state.stockKeepingUnits);
  };

  removeButtonClicked = (e) => {
    e.preventDefault();
  };

  handleOnError = () => {
    console.log("UPLOAD ERROR");
  };
  handleOnFileLoad = (data) => {
    let bulkOrders = [];
    console.log(data);
    data.map((element, idx) => {
      if (idx !== 0) {
        let order_bulk = {
          referenceNumber: uuidv4().replace(/-/g, ""),
          orderType: "Consumer",
          customerCompanyName: "Test Inc.",
          customerFullName: element.data[0],
          customerStreetAddress1: element.data[2],
          customerStreetAddress2: "",
          customerStreetAddress3: "",
          customerCity: element.data[3],
          customerStateOrProvince: element.data[4],
          customerPostalCode: element.data[5],
          customerCountry: element.data[6],
          customerEmailAddress: element.data[1],
          customerTelephoneNumber: element.data[7],
          orderShippingMethod: "UPS Ground",
          orderComments: "Fragile. This side up.",
          orderSubTotalAmount: 0.0,
          orderTaxAmount: 0.0,
          orderShippingCostAmount: 0.0,
          orderTotalAmount: 0.0,
          // stockKeepingUnits: [
          //   {
          //     stockKeepingUnit: "shifu014",
          //     quantityOrdered: 1,
          //     unitPrice: 50.0,
          //   },
          // ],
          stockKeepingUnits: [],
        };

        let sku_array = element.data[8].split(",");
        sku_array.forEach((sku) => {
          if (sku !== "") {
            order_bulk.stockKeepingUnits.push({
              stockKeepingUnit: sku_mapping[sku.split(":")[0]],
              quantityOrdered: parseInt(sku.split(":")[1]),
              unitPrice: price_mapping[sku_mapping[sku.split(":")[0]]],
            });
          }
        });
        let orderSubTotalAmount = 0;
        order_bulk.stockKeepingUnits.forEach((unit) => {
          orderSubTotalAmount += price_mapping[unit.stockKeepingUnit];
        });
        order_bulk.orderSubTotalAmount = orderSubTotalAmount;
        order_bulk.orderTotalAmount = orderSubTotalAmount;
        console.log("TIME STAMP REF NUM", order_bulk);
        bulkOrders.push(order_bulk);
      }
    });
    this.setState({ bulkOrders: bulkOrders });
    console.log("FILE UPLOAD", bulkOrders);
  };
  getTotalAmount = () => { };
  uploadCSV = (e) => {
    e.preventDefault();
  };
  render() {
    return (
      <div className="manual-order">
        <div className="user-details">
          <div class="wrapper">
            <div class="container">
              <form action="">
                <h1>
                  <i class="fas fa-shipping-fast"></i>
                  Shipping Details
                </h1>
                <div class="name">
                  <div>
                    <label for="f-name">Name</label>
                    <input
                      type="text"
                      name="f-name"
                      value={this.state.customerFullName}
                      onChange={(e) => this.setState({ customerFullName: e.target.value })}
                    />
                  </div>
                  <div>
                    <label for="l-name">Email</label>
                    <input
                      type="text"
                      name="l-name"
                      value={this.state.customerEmailAddress}
                      onChange={(e) => this.setState({ customerEmailAddress: e.target.value })}
                    />
                  </div>
                </div>
                <div class="street">
                  <label for="name">Address</label>
                  <input
                    type="text"
                    name="address"
                    value={this.state.customerStreetAddress1}
                    onChange={(e) => this.setState({ customerStreetAddress1: e.target.value })}
                  />
                </div>
                <div class="address-info">
                  <div>
                    <label for="city">City</label>
                    <input
                      type="text"
                      name="city"
                      value={this.state.customerCity}
                      onChange={(e) => this.setState({ customerCity: e.target.value })}
                    />
                  </div>
                  <div>
                    <label for="state">State</label>
                    <input
                      type="text"
                      name="state"
                      value={this.state.customerStateOrProvince}
                      onChange={(e) => this.setState({ customerStateOrProvince: e.target.value })}
                    />
                  </div>
                  <div>
                    <label for="zip">Zip</label>
                    <input
                      type="text"
                      name="zip"
                      value={this.state.customerPostalCode}
                      onChange={(e) => this.setState({ customerPostalCode: e.target.value })}
                    />
                  </div>
                </div>

                <div class="cc-info">
                  <div>
                    <label for="card-num">Country</label>
                    <input
                      type="text"
                      name="expire"
                      value={this.state.customerCountry}
                      onChange={(e) => this.setState({ customerCountry: e.target.value })}
                    />
                  </div>
                  <div>
                    <label for="card-num">Phone Number</label>
                    <input
                      type="text"
                      name="security"
                      value={this.state.customerTelephoneNumber}
                      onChange={(e) => this.setState({ customerTelephoneNumber: e.target.value })}
                    />
                  </div>
                </div>
                <div class="btns">
                  <button onClick={(e) => this.onPlaceOrder(e)}>Place Order</button>
                  {/* <button>Back to cart</button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="order-details">
          {" "}
          <div class="wrapper">
            <div class="container">
              <form action="">
                <h1>
                  <i class="fas fa-shipping-fast"></i>
                  Order Details
                </h1>
                <div class="name">
                  <div className="column">
                    <label for="f-name">SKU</label>
                    <select class="browser-default custom-select" onChange={(e) => this.selectedSKU(e)}>
                      <option selected>Select SKU </option>
                      <option value="orboot">Orboot</option>
                      <option value="orboot_dinos">Orboot-Dinos</option>
                      <option value="orboot_mars">Orboot-Mars</option>
                      <option value="plugo_link">Plugo Link WG</option>
                      <option value="plugo_count">Plugo Count WG</option>
                      <option value="plugo_letters">Plugo Letters WG</option>
                      <option value="plugo_piano">Plugo Piano WG</option>
                      <option value="stemwizpack">Plugo Stemwizpack </option>
                      <option value="plugo_slingshot">Plugo Slingshot WG</option>
                      <option value="link">Plugo Link</option>
                      <option value="count">Plugo Count</option>
                      <option value="letters">Plugo Letters</option>
                      <option value="piano">Plugo Piano</option>
                      <option value="slingshot">Plugo Slingshot</option>
                      <option value="plugo_gamepad">Plugo Gamepad</option>
                    </select>
                  </div>
                  <div>
                    <label for="l-name">Quantity</label>
                    <select class="browser-default custom-select" onChange={(e) => this.selectedQuantity(e)}>
                      <option selected>Select Quantity</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                </div>
                <div>
                  {this.state.selectedArrayString !== "" ? (
                    <ul style={{ listStyleType: "none" }}>
                      {this.state.selectedArrayString.split(",").map((entry, idx) => (
                        <li
                          style={{
                            backgroundColor: "#e5e5e5",
                            borderRadius: "10px",
                            marginTop: "3px",
                            width: "100%",
                          }}>
                          {entry}{" "}
                          <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={(e) => this.removeButtonClicked(e)}
                            edge="start"
                            style={{ height: "15px" }}>
                            <CancelIcon style={{ height: "15px", marginLeft: "30px" }} />
                          </IconButton>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
                <div class="btns">
                  <button onClick={(e) => this.addButtonClicked(e)}>Add</button>
                </div>
              </form>
            </div>
          </div>
          <div class="wrapper">
            <div class="container">
              <form action="">
                <h1>
                  <i class="fas fa-shipping-fast"></i>
                  Bulk Orders
                </h1>

                <div class="btns">
                  <CSVReader
                    onFileLoad={this.handleOnFileLoad}
                    onError={this.handleOnError}
                    noDrag
                    onRemoveFile={this.handleOnRemoveFile}>
                    <span type="button">Click to upload csv</span>
                  </CSVReader>
                  <button type="upload-button button" onClick={this.placeOrderBulk}>
                    Place Order
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
