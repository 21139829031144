import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";
import { firebase } from "../firebase";
import {
  cancelOrder,
  cancelItem,
  updateUserDetails,
  updateShipmentLink,
} from "../actions/orderActions";
import { connect } from "react-redux";
import { render } from "@testing-library/react";

const mysql = require("mysql");

const db = firebase.firestore();

const mysqlConn = mysql.createConnection({
  host: "ap-south1-mysql-instance1.cswystw6zsdo.ap-south-1.rds.amazonaws.com",
  user: "admin",
  password: "shifudev2016",
  database: "ecomm_prod",
  multipleStatements: true,
});
const _ = require("lodash");
const uuidv4 = require("uuid/v4");
const moment = require("moment");
class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ordersArray: [],
      open: false,
      fieldUpdated: false,
      name: "",
      email: "",
      address: "",
      number: "",
      shipmentLink: "",
      selectedSku: "",
      id: "",
      link: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    // console.log("USE EFECT CALLED", nextProps.data);

    this.setState({
      open: nextProps.open,
      email: nextProps.data[0][2],
      name: nextProps.data[0][1],
      address: nextProps.data[0][13],
      number: nextProps.data[0][12],
      id: nextProps.data[0][14],
      link: nextProps.data[0][16],
    });
    if (nextProps.data[0][8] && nextProps.data[0][8] !== "") {
      let orderArray = nextProps.data[0][8].split(",");
      orderArray = orderArray.filter(function (el) {
        if(el.split(":")[1] === "Backorder"){
          return el ;

        }
      });
      let ordersArray = [];
      orderArray.map((order) => {
        ordersArray.push(order);
      });
      // console.log("ORDER ARRAY", ordersArray);
      this.setState({ ordersArray: ordersArray });
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };
  onCancelItem = (e, sku) => {
    e.preventDefault();
    this.props.cancelItem(sku, this.props.data[0][8]);
    this.handleClose();
  };

  onChangeName = (e) => {
    this.setState({ name: e.target.value });
  };
  onChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };
  onChangeAddress = (e) => {
    this.setState({ address: e.target.value });
  };
  onChangeNumber = (e) => {
    this.setState({ number: e.target.value });
  };
  onChangeTrackingLink = (e) => {
    e.preventDefault();
    this.setState({ shipmentLink: e.target.value });
  };

  onUpdateDetails = async(e) => {
    e.preventDefault();
    console.log(this.props.data[0][14])
    let id = this.props.data[0][14]
    await db
    .collection("web-orders")
    .doc(id)
    .get()
    .then(async(doc) => {
      let data = doc.data();
      data.sku.forEach((sku) => {
        this.state.selectedSku.forEach((item) => {
          if (sku.sku === item) {
            console.log("here")
            sku.link = this.state.shipmentLink;
          }
        });
      });
      console.log("DATA TO POST IN FIREBASE", data);
      // await db.collection("web-orders").doc(shipment_object.order_id).set(data);
    });
    // this.props.updateUserDetails(
    //   {
    //     name: this.state.name,
    //     email: this.state.email,
    //     address: this.state.address,
    //     number: this.state.number,
    //     orders: this.state.ordersArray,
    //   },
    //   this.props.data[0][8]
    // );
    this.handleClose();
  };
  onChangeQuantity = (e, currentSku) => {
    e.preventDefault();
    console.log("ORDERS ARRAY", currentSku, e.target.value);
    let new_ordersArray = this.state.ordersArray;
    this.state.ordersArray.map((order, i) => {
      let sku = order.split(":")[0];
      if (sku === currentSku) new_ordersArray[i] = `${sku}:${e.target.value}`;
    });
    this.setState({
      ordersArray: new_ordersArray,
      fieldUpdated: !this.state.fieldUpdated,
    });

    // handleClose();
  };

  onCancelOrder = () => {
    db.collection("web-orders").doc(this.state.id).update("is_cancel", 1);
    db.collection("web-orders")
      .doc(this.state.id)
      .get()
      .then((doc) => {
        let document = doc.data();
        fetch(
          `https://us-central1-mws-sales-data.cloudfunctions.net/cancelWebOrders?cartid=${document.cartId}`
        ).then((res) => {
          if (res.status === 200) {
            alert("Order cancelled successfully");
            window.location.reload();
          } else {
            alert("Please try again")
          }
        });
      });
  };
  updateShipmentLink = (e) => {
    e.preventDefault();
    let ship_qty = []
    let sku_array = this.props.data[0][4].split(",");
    console.log("ORDER ID", this.state.shipmentLink ,this.props.data[0][4]);
    sku_array.forEach((element) => {
      console.log(element, this.state.selectedSku);
      if (this.state.selectedSku.includes(element.split(":")[0])) {
        ship_qty.push(parseInt(element.split(":")[1]));
      }
    });
    let id = uuidv4().replace(/-/g, "");
    let order_id = this.props.data[0][14];
    let ship_item = this.state.selectedSku;
    let link = this.state.shipmentLink;
    let created_at = moment().format("YYYY-MM-DD HH:mm:ss");
    let is_cancel = 0;
    let name = this.props.data[0][1];
    let email = this.props.data[0][2];
    console.log (
      id,
      order_id,
      ship_item,
      ship_qty,
      link,
      created_at,
      is_cancel,
      name,
      email
    )
    this.props.updateShipmentLink(
      id,
      order_id,
      ship_item,
      ship_qty,
      link,
      created_at,
      is_cancel,
      name,
      email
    );
    this.setState({ open: false });
  };
  handleChangeCheckbox = (e, sku) => {
    console.log(sku)
    let skuArray = [...this.state.selectedSku, sku];
    if (this.state.selectedSku.includes(sku)) {
      skuArray = skuArray.filter((sku) => sku !== sku);
    }
    this.setState({ selectedSku: skuArray });
    console.log(this.state.selectedSku);
  };      
  render() {
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle style={{ marginTop: "2%" }} id="form-dialog-title">
            Order Information : {" "}
            <Button
              style={{ marginLeft: "75%" }}
              variant="contained"
              onClick={this.onCancelOrder}
              color="primary"
            >
              Cancel Order
            </Button>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Basic Details for the order are mentioned here:
            </DialogContentText>
            <TextField
              autoFocus
              onChange={(e) => this.onChangeEmail(e)}
              margin="dense"
              id="email"
              label="Email"
              type="email"
              value={this.state.email}
              fullWidth
              disabled
            />
            <TextField
              autoFocus
              onChange={(e) => this.onChangeName(e)}
              margin="dense"
              id="name"
              label="Name"
              type="name"
              value={this.state.name}
              fullWidth
              disabled
            />
            <TextField
              autoFocus
              onChange={(e) => this.onChangeAddress(e)}
              margin="dense"
              id="address"
              label="Address"
              type="address"
              value={this.state.address}
              fullWidth
              disabled
            />
            <TextField
              autoFocus
              onChange={(e) => this.onChangeNumber(e)}
              margin="dense"
              id="number"
              label="Number"
              type="text"
              value={this.state.number}
              fullWidth
              disabled
            />
            <TextField
              autoFocus
              // onChange={(e) => this.onChangeNumber(e)}
              margin="dense"
              id="link"
              label="Tracking Link"
              type="Tracking Link"
              value={this.state.link}
              fullWidth
              disabled
            />
            {this.state.ordersArray.length > 0 ? (
            <h3>Backorders Link Update:</h3>

            ) : null}
            {this.state.ordersArray.map((order) => (
              <div style={{ display: "flex", displayDirection: "row" }}>
                <Checkbox
                  onChange={(e) =>
                    this.handleChangeCheckbox(e, order.split(":")[0])
                  }
                  color="primary"
                  label={`${order.split(":")[0]}`}
                  inputProps={{ "aria-label": `${order.split(":")[0]}` }}
                />
                <p>{order.split(":")[0]}:</p>{" "}
                {/* <TextField
                            style={{ marginLeft: "2rem" }}
                            autoFocus
                            margin="dense"
                            id="orders"
                            onChange={(e) => this.onChangeTrackingLink(e)}
                            type="name"
                            // value={this.state.shipmentLink}
                          /> */}
                          {/* <Button
                            onClick={(e) => this.updateShipmentLink(e)}
                            style={{ marginLeft: "1rem", marginTop: "1rem" }}
                            color="primary"
                          >
                            Submit
                          </Button> */}
                {/* <TextField
                  style={{ marginLeft: "2rem" }}
                  autoFocus
                  margin="dense"
                  id="orders"
                  onChange={(e) =>
                    this.onChangeQuantity(e, order.split(":")[0])
                  }
                  type="name"
                  value={order.split(":")[1]}
                /> */}
                {/* <Button
                  onClick={(e) => this.onCancelItem(e, order.split(":")[0])}
                  style={{ marginLeft: "1rem", marginTop: "1rem" }}
                  color="primary"
                >
                  Cancel Item
                </Button> */}
              </div>
            ))}
            {this.state.ordersArray.length > 0 ? (
                          <div style={{ display: "flex", displayDirection: "row" }}>
                          <p>Tracking Link:</p>{" "}
                          <TextField
                            style={{ marginLeft: "2rem" }}
                            autoFocus
                            margin="dense"
                            id="orders"
                            onChange={(e) => this.onChangeTrackingLink(e)}
                            type="name"
                            value={this.state.shipmentLink}
                          />
                          {/* <Button
                            onClick={(e) => this.updateShipmentLink(e)}
                            style={{ marginLeft: "1rem", marginTop: "1rem" }}
                            color="primary"
                          >
                            Submit
                          </Button> */}
                        </div>
            ) : null}

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
            {this.state.ordersArray.length > 0 ?  (
                          <Button
                          onClick={(e) => this.updateShipmentLink(e)}
                          style={{ marginLeft: "1rem" }}
                          color="primary"
                        >
                          Submit
                        </Button>
            ): null}

          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  webOrders: state.webOrders.webOrders,
});
const mapDispatchToProps = (dispatch) => {
  return {
    cancelItem: (orderId, sku) => {
      cancelItem(dispatch, orderId, sku);
    },
    updateUserDetails: (details, orderId) => {
      updateUserDetails(dispatch, details, orderId);
    },
    updateShipmentLink: (
      id,
      order_id,
      ship_item,
      ship_qty,
      link,
      created_at,
      is_cancel,
      name,
      email
    ) => {
      updateShipmentLink(
        dispatch,
        id,
        order_id,
        ship_item,
        ship_qty,
        link,
        created_at,
        is_cancel,
        name,
        email
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
