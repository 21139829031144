import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";

let initializedFirebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAETpq64GtwkEKFEZuRCswCOh7zysydfaQ",
  authDomain: "mws-sales-data.firebaseapp.com",
  databaseURL: "https://mws-sales-data.firebaseio.com",
  projectId: "mws-sales-data",
  storageBucket: "mws-sales-data.appspot.com",
  messagingSenderId: "696881684171",
  appId: "1:696881684171:web:2af7049ba2d6fc5e",
});

// const messaging = initializedFirebaseApp.messaging();
// messaging.usePublicVapidKey(
//   "BLYVlKf6PHMeeaxa3E68g4GSPBOiN5CmwQVHHNB8_sScLwGgql0Xc9Ll2Xkoa3oDWIwDxu06f5Aj8gRyXSqTwWs",
// );

export { firebase };
