import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { firebase } from "../../../firebase";
import {
	getWebOrders,
	getRakutenItems,
	getRakutenOrders,
} from "../../../actions/getDataAction";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";
import {
	cancelOrder,
	cancelItem,
	updateUserDetails,
} from "../../../actions/orderActions";
import Popup from "../../../Components/Popup";
import PopupManual from "../../../Components/PopupManual";
import PopupRakutenButton from "../../../Components/PopupRakutenButton";
import Button from "@material-ui/core/Button";
import { Table, Collapse } from "react-bootstrap";
import InputLabel from "@material-ui/core/InputLabel";
import Loading from "../../../Components/Loading";
import styled from "styled-components";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import "./style.scss";
import { AddAlarmOutlined } from "@material-ui/icons";
import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";
const _ = require("lodash");
const moment = require("moment");
const axios = require("axios");

const db = firebase.firestore();
const sku_mapping = {
	orboot: "shifu014",
	orboot_dinos: "shifu027",
	orboot_mars: "shifu028",
	stemwizpack: "shifu026",
	plugo_piano: "shifu022WG",
	piano: "shifu022",
	plugo_link: "shifu019WG",
	link: "shifu019",
	plugo_count: "shifu020WG",
	count: "shifu020",
	plugo_letters: "shifu025WG",
	letters: "shifu025",
	plugo_slingshot: "shifu023WG",
	slingshot: "shifu023",
	plugo_gamepad: "shifu034",
	tacto_laser: "Shifu030",
	// tacto_bundle_lc: "shifu035",
	tacto_classics: "shifu036",
	tacto_chess: "Shifu035",
	tacto_coding: "Shifu033",
	tacto_electronic: "Shifu038",
	tacto_dinos: "Shifu037",
	plugo_farm: "Shifu039WG",
	plugo_detective: "Shifu040WG",
	plugo_animals: "Shifu042WG",
	tacto_doctor: "Shifu041",
	plugo_coding: "Shifu044WG"
};
const price_mapping = {
	shifu014: 59.99,
	shifu027: 59.99,
	shifu026: 80.0,
	shifu022WG: 35.0,
	shifu022: 50.0,
	shifu019WG: 39.99,
	shifu019: 69.99,
	shifu020WG: 39.99,
	shifu020: 69.99,
	shifu025WG: 39.99,
	shifu025: 69.99,
	shifu023WG: 39.99,
	shifu023: 50.0,
	shifu034: 30.0,
	Shifu030: 49.99,
	Shifu035: 49.99,
	shifu036: 49.99,
	Shifu033: 49.99,
	shifu028: 59.99,
	Shifu038: 59.99,
	Shifu037: 59.99,
	Shifu039WG: 39.99,
	Shifu040WG: 39.99,
	Shifu042WG: 39.99,
	Shifu041: 59.99,
	Shifu044WG: 39.99,
};

const comboSkuToSkuMap = {
	tacto_stem_pack_3: [sku_mapping.tacto_laser, sku_mapping.tacto_coding, sku_mapping.tacto_electronic],
	tacto_night_cc: [sku_mapping.tacto_chess, sku_mapping.tacto_classics]
}
const StyledButton = styled(Button)`
	&:hover {
		background: "transparent";
	}
`;

class DataTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ordersArray: [],
			shippedOrders: [],
			shipmentLink: "",
			selectedSku: [],
			table_data: [],
			open: false,
			openM: false,
			openR: false,
			popupData: [[]],
			manualData: [[]],
			rakutenItems: [],
			rakutenData: [],
			authToken: "",
			age: 10,
			loading: false,
			hasQueryParams: false,
			hidden: "hidden",
			openTable: false,
		};
	}

	componentWillMount() {
		var that = this;
		setTimeout(function () {
			that.show();
			clearTimeout();
		}, 7000);
		this.getAuthToken();

		this.props.getWebOrders();
	}
	show() {
		this.setState({ hidden: "" });
	}
	async componentWillReceiveProps(nextProps) {
		// console.log(nextProps.rakutenItems);
		this.setState({
			table_data: nextProps.webOrders,
			rakutenItems: nextProps.rakutenItems,
		});
	}

	onRowsDelete(rowsDeleted) {
		let deleted_data = [];
		rowsDeleted.data.forEach((row) => {
			deleted_data.push(this.state.table_data[row.index]);
		});
		this.setState({ open: true, popupData: deleted_data });
	}
	onCancelOrder = () => {
		this.props.cancelOrder(this.state.popupData[0][8]);
		this.setState({ open: false });
	};
	detailButtonClicked = (e, value, tableMeta) => {
		e.preventDefault();
		let deleted_data = [];
		deleted_data.push(this.state.table_data[tableMeta.rowIndex]);
		console.log("DATA DETAILS", deleted_data);
		this.setState({ open: true, popupData: deleted_data });

		console.log("DETAIL BUTTON", e, value, tableMeta);
	};

	manualButtonClicked = (e, value, tableMeta) => {
		e.preventDefault();
		let manual_data = [];
		manual_data.push(this.state.table_data[tableMeta.rowIndex]);
		console.log("MANUAL", manual_data);
		this.setState({ openM: true, manualData: manual_data });
	};

	rakutenButtonClicked = (e, value, tableMeta) => {
		e.preventDefault();
		let rakuten_data = [];
		rakuten_data.push(this.state.table_data[tableMeta.rowIndex]);
		console.log(
			"rakutenButtonClickeds",
			rakuten_data,
			this.state.table_data[tableMeta.rowIndex][6].split(","),
			this.state.table_data[tableMeta.rowIndex][8].split(",")
		);
		this.setState({ openR: true, rakutenData: rakuten_data });
		if (
			rakuten_data[0][6] &&
			rakuten_data[0][6] !== "" &&
			rakuten_data[0][6] !== "Unshipped" &&
			rakuten_data[0][8] !== "Unshipped"
		) {
			let orderArray = rakuten_data[0][4].split(",");
			orderArray = orderArray.filter(function (el) {
				return el != "";
			});
			let ordersArray = [];
			let splitOrders = [];
			orderArray.map((order) => {
				ordersArray.push(order);
				order = order.split(":")[0];
				splitOrders.push(order);
			});

			let shippedOrder = rakuten_data[0][6]
				.split(",")
				.concat(rakuten_data[0][8].split(","));
			shippedOrder = shippedOrder.filter(function (el) {
				return el != "";
			});
			let shippedOrders = [];
			let splitShipped = [];
			shippedOrder.map((order) => {
				shippedOrders.push(order);
				order = order.split(":")[0];
				splitShipped.push(order);
			});
			splitOrders = splitOrders.filter(
				(val) => !splitShipped.includes(val)
			);
			console.log(
				"UNSHIPPED",
				splitOrders,
				ordersArray,
				shippedOrders,
				splitShipped
			);
			if (splitOrders.length === 0) {
				this.setState({ shippedOrders: ordersArray, ordersArray: [] });
			} else {
				let finalShipped = [];
				let finalUnshipped = [];
				splitOrders.forEach((order) => {
					orderArray.forEach((ord) => {
						if (order === ord.split(":")[0]) {
							finalUnshipped.push(ord);
						}
					});
				});
				ordersArray.forEach((order) => {
					splitShipped.forEach((ord) => {
						if (order.includes(ord)) {
							finalShipped.push(order);
						}
					});
				});
				console.log(finalShipped, finalUnshipped);
				this.setState({
					ordersArray: finalUnshipped,
					shippedOrders: finalShipped,
				});
			}
		} else if (
			rakuten_data[0][6] &&
			rakuten_data[0][6] !== "" &&
			rakuten_data[0][6] !== "Unshipped"
		) {
			let orderArray = rakuten_data[0][4].split(",");
			orderArray = orderArray.filter(function (el) {
				return el != "";
			});
			let ordersArray = [];
			let splitOrders = [];
			orderArray.map((order) => {
				ordersArray.push(order);
				order = order.split(":")[0];
				splitOrders.push(order);
			});

			let shippedOrder = rakuten_data[0][6].split(",");
			shippedOrder = shippedOrder.filter(function (el) {
				return el != "";
			});
			let shippedOrders = [];
			let splitShipped = [];
			shippedOrder.map((order) => {
				shippedOrders.push(order);
				order = order.split(":")[0];
				splitShipped.push(order);
			});
			splitOrders = splitOrders.filter(
				(val) => !splitShipped.includes(val)
			);
			console.log(
				"UNSHIPPED",
				splitOrders,
				ordersArray,
				shippedOrders,
				splitShipped
			);
			if (splitOrders.length === 0) {
				this.setState({ shippedOrders: ordersArray, ordersArray: [] });
			} else {
				let finalShipped = [];
				let finalUnshipped = [];
				splitOrders.forEach((order) => {
					orderArray.forEach((ord) => {
						if (order === ord.split(":")[0]) {
							finalUnshipped.push(ord);
						}
					});
				});
				ordersArray.forEach((order) => {
					splitShipped.forEach((ord) => {
						if (order.includes(ord)) {
							finalShipped.push(order);
						}
					});
				});
				console.log(finalShipped, finalUnshipped);
				this.setState({
					ordersArray: finalUnshipped,
					shippedOrders: finalShipped,
				});
			}
		} else if (
			rakuten_data[0][8] &&
			rakuten_data[0][8] !== "" &&
			rakuten_data[0][8] !== "Unshipped"
		) {
			let orderArray = rakuten_data[0][4].split(",");
			orderArray = orderArray.filter(function (el) {
				return el != "";
			});
			let ordersArray = [];
			let splitOrders = [];
			orderArray.map((order) => {
				ordersArray.push(order);
				order = order.split(":")[0];
				splitOrders.push(order);
			});

			let shippedOrder = rakuten_data[0][8].split(",");
			shippedOrder = shippedOrder.filter(function (el) {
				return el != "";
			});
			let shippedOrders = [];
			let splitShipped = [];
			shippedOrder.map((order) => {
				shippedOrders.push(order);
				order = order.split(":")[0];
				splitShipped.push(order);
			});
			splitOrders = splitOrders.filter(
				(val) => !splitShipped.includes(val)
			);
			console.log(
				"UNSHIPPED",
				splitOrders,
				ordersArray,
				shippedOrders,
				splitShipped
			);
			if (splitOrders.length === 0) {
				this.setState({ shippedOrders: ordersArray, ordersArray: [] });
			} else {
				let finalShipped = [];
				let finalUnshipped = [];
				splitOrders.forEach((order) => {
					orderArray.forEach((ord) => {
						if (order === ord.split(":")[0]) {
							finalUnshipped.push(ord);
						}
					});
				});
				ordersArray.forEach((order) => {
					splitShipped.forEach((ord) => {
						if (order.includes(ord)) {
							finalShipped.push(order);
						}
					});
				});
				console.log(finalShipped, finalUnshipped);
				this.setState({
					ordersArray: finalUnshipped,
					shippedOrders: finalShipped,
				});
			}
		} else if (
			rakuten_data[0][4] &&
			rakuten_data[0][4] !== "" &&
			rakuten_data[0][8] === "Unshipped"
		) {
			let orderArray = rakuten_data[0][4].split(",");
			orderArray = orderArray.filter(function (el) {
				return el != "";
			});
			let ordersArray = [];
			orderArray.map((order) => {
				ordersArray.push(order);
			});
			console.log("ORDER ARRAY 1", ordersArray);
			this.setState({ ordersArray: ordersArray, shippedOrders: [] });
		} else {
			console.log("RANDOM MESSAGE");
		}
		// this.createOrder(this.state.table_data[tableMeta.rowIndex]);
	};

	createOrder = async () => {
		let data = this.state.rakutenData[0];
		console.log("CREATE ORDER CALLED", data, this.state.selectedSku);
		let skus = this.state.selectedSku;
		let length = 0;
		// this.setState({ loading: true });

		await db
			.collection("web-orders")
			.doc(data[14])
			.get()
			.then(async (docSnapshot) => {
				let data = docSnapshot.data();
				await db
					.collection("rakuten-orders")
					.get()
					.then((documents) => {
						documents.forEach((doc) => {
							if (
								doc.data().referenceNumber.split("_")[0] ===
								data.orderId
							) {
								length += 1;
								console.log(length);
							}
						});
					});
			});

		let order = {
			referenceNumber: length === 0 ? data[14] : data[14] + `_${length}`,
			orderType: "Consumer",
			customerCompanyName: "Customer Inc.",
			customerFullName: data[1],
			customerStreetAddress1: data[13],
			customerStreetAddress2: "",
			customerStreetAddress3: "",
			customerCity: data[9],
			customerStateOrProvince: data[15],
			customerPostalCode: data[10],
			customerCountry: data[3],
			customerEmailAddress: data[2],
			customerTelephoneNumber: data[12] !== undefined ? data[12] : "",
			orderShippingMethod: "UPS Ground",
			orderSubTotalAmount: 50.0,
			orderTaxAmount: 0.0,
			orderShippingCostAmount: 0.0,
			orderTotalAmount: 50.0,
			// stockKeepingUnits: [
			//   {
			//     stockKeepingUnit: "shifu014-SMC",
			//     quantityOrdered: 1,
			//     unitPrice: 50.0,
			//   },
			// ],
			stockKeepingUnits: [],
		};
		console.log("ORDER", order);
		let sku_array = data[4].split(",");
		sku_array.forEach((sku) => {
			const stockKeepingUnit = sku.split(":")[0];
			if (sku !== "" && !comboSkuToSkuMap[stockKeepingUnit]) {
				order.stockKeepingUnits.push({
					stockKeepingUnit: sku_mapping[stockKeepingUnit],
					quantityOrdered: parseInt(sku.split(":")[1]),
					unitPrice: price_mapping[sku_mapping[stockKeepingUnit]],
				});
			} else if (sku !== "" && comboSkuToSkuMap[stockKeepingUnit]) {
				comboSkuToSkuMap[stockKeepingUnit].forEach((item) => {
					order.stockKeepingUnits.push({
						stockKeepingUnit: item,
						quantityOrdered: parseInt(sku.split(":")[1]),
						unitPrice: price_mapping[item],
					});
				})

			}
		});
		let orderSubTotalAmount = 0;
		order.stockKeepingUnits.forEach((unit) => {
			orderSubTotalAmount += price_mapping[unit.stockKeepingUnit];
		});
		order.orderSubTotalAmount = orderSubTotalAmount;
		order.orderTotalAmount = orderSubTotalAmount;
		console.log("TIME STAMP REF NUM", order);
		console.log("OAUTH", order);

		fetch(`https://api.shipnetwork.com/Orders`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
				// type: "no-cors",
				Authorization: `Bearer ${this.state.authToken}`,
				ClientId: "5004",
			},
			body: JSON.stringify(order),
		})
			.then(async (response) => {
				console.log("RESPONSE", response);
				this.setState({ loading: false });
				if (response.status == 202) {
					await db
						.collection("web-orders")
						.doc(data[14])
						.get()
						.then(async (doc) => {
							let data = doc.data();
							let sent_rakuten = [];
							console.log(doc.data());
							for (let i = 0; i < skus.length; i++) {
								for (let j = 0; j < data.sku.length; j++) {
									if (skus[i] === data.sku[j].sku) {
										data.sku[j].status = "Sent";
										console.log(data.sku[j]);
									}
								}
							}

							await db
								.collection("web-orders")
								.doc(data.orderId)
								.update(data);
						});

					await db
						.collection("web-orders")
						.doc(data[14])
						.get()
						.then((doc) => {
							let sku_length = doc.data().sku.length;
							let sent_length = 0;
							doc.data().sku.forEach(async (sku) => {
								console.log(sku);
								if (sku.status === "Sent") {
									sent_length += 1;
								}
								console.log(sku_length, sent_length);

								if (sku_length === sent_length) {
									await db
										.collection("web-orders")
										.doc(data[14])
										.update({ rakuten_status: "Sent" });
								}
							});
						});
					alert("Order placed successfully");
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				} else {
					db.collection("web-orders")
						.doc(data[14])
						.update({ rakuten_status: "Not Accepted" });
					alert("Order not accepted. Do manual");
				}
			})
			.then((result) => {
				// response.json();
				console.log("RESULT", result);
			})
			.catch((err) => console.log("FETCH ERROR1", err));
	};
	getAuthToken = () => {
		fetch(`https://api.shipnetwork.com/Auth`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
			},
			body: JSON.stringify({
				clientId: "5004",
				apiUserIdentifier: "455575fb-86a9-413d-ade3-ebf2a9d527f2",
				apiUserSecret:
					"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NDU3NjkxNzMsImlzcyI6IlJha3V0ZW5TdXBlckxvZ2lzdGljcy5TZWN1cml0eSIsImF1ZCI6IlJha3V0ZW5TdXBlckxvZ2lzdGljcy5TZWN1cml0eSJ9.j5gCrUqdh8pddCQR7LRb1fvk9oeNH5aY_WrFsX2csmM",
			}),
		})
			.then((response) => response.json())
			.then((result) => {
				this.setState({ authToken: result.data.token });
				this.props.getRakutenItems(result.data.token);
				this.props.getRakutenOrders(result.data.token);
				// console.log("FETCH RESPONSE AUTH TOKEN", result);
			})
			.catch((err) => console.log("FETCH ERROR", err));
	};
	checkRakutenEligiblity = (data) => {
		let orders_array = data[4].split(",");
		let eligiblityCheck = true;
		orders_array.forEach((entry) => {
			if (data[3] !== "US" || data[14] === "HI" || data[14] === "AK")
				eligiblityCheck = false;
		});

		return eligiblityCheck;
	};
	getRakutenOrders = () => {
		fetch(`https://api.shipnetwork.com/Backorders`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
			},
			queryParameters: JSON.stringify({
				ReferenceNumber: "5004",
				apiUserIdentifier: "455575fb-86a9-413d-ade3-ebf2a9d527f2",
				apiUserSecret:
					"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NDU3NjkxNzMsImlzcyI6IlJha3V0ZW5TdXBlckxvZ2lzdGljcy5TZWN1cml0eSIsImF1ZCI6IlJha3V0ZW5TdXBlckxvZ2lzdGljcy5TZWN1cml0eSJ9.j5gCrUqdh8pddCQR7LRb1fvk9oeNH5aY_WrFsX2csmM",
			}),
		})
			.then((response) => response.json())
			.then((result) => {
				this.setState({ authToken: result.data.token });
				console.log("FETCH RESPONSE GET RAKUTEN ORDERS", result);
			})
			.catch((err) => console.log("FETCH ERROR", err));
	};
	getRakutenItems = () => {
		fetch(`https://api.shipnetwork.com/Items`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
				Authorization: `Bearer ${this.state.authToken}`,
				ClientId: "5004",
			},
		})
			.then((response) => response.json())
			.then((result) => {
				this.setState({ authToken: result.data.token });
				console.log("FETCH RESPONSE GET RAKUTEN ORDERS", result);
			})
			.catch((err) => console.log("FETCH ERROR", err));
	};
	handleCloseManual = () => {
		this.setState({ openM: false });
	};
	handleCloseRakuten = () => {
		this.setState({ openR: false });
	};
	handleChangeStatusSelect = (e, tableData) => {
		console.log("EVENT", e.target.value);
		if (e.target.value === "In Fulfillment") {
			console.log("VALUE", tableData);
			db.collection("web-orders")
				.doc(tableData[14])
				.update({ shipped_status: "In Fulfillment" })
				.then((res) => console.log(res))
				.catch((err) => console.log(err));
		} else if (e.target.value === "Unshipped") {
			db.collection("web-orders")
				.doc(tableData[14])
				.update({ shipped_status: "Unshipped" })
				.then((res) => console.log(res))
				.catch((err) => console.log(err));
		}
	};

	handleChangeCheckbox = (e, sku) => {
		let skuArray = [...this.state.selectedSku, e.target.value];
		if (this.state.selectedSku.includes(e.target.value)) {
			skuArray = skuArray.filter((sku) => sku !== e.target.value);
		}
		this.setState({ selectedSku: skuArray });
		console.log(this.state.selectedSku);
	};

	render() {
		const search = this.props.location.search;
		const email = new URLSearchParams(search).get("email");
		// console.log("email here is", email);
		const columns = [
			{
				name: "date",
				label: "Date",
				options: {
					filter: false,
					sort: true,
				},
			},
			{
				name: "cutomer_name",
				label: "Customer Name",
				options: {
					filter: false,
					sort: false,
				},
			},
			{
				name: "email",
				label: "Email",
				options: {
					filter: false,
					sort: false,
				},
			},
			{
				name: "country",
				label: "Country",
				options: {
					filter: false,
					sort: false,
				},
			},
			{
				name: "sku",
				label: "SKU",
				options: {
					filter: false,
					sort: false,
				},
			},
			{
				name: "payType",
				label: "Payment Gateway",
				options: {
					filter: false,
					sort: false,
				},
			},
			{
				name: "web status",
				label: "Web Status",
				options: {
					filter: false,
					sort: false,
				},
			},
			{
				name: "rakuten status",
				label: "Rakuten Status",
				options: {
					filter: true,
					customBodyRender: (value, tableMeta, updateValue) => {
						if (
							this.checkRakutenEligiblity(
								this.state.table_data[tableMeta.rowIndex]
							)
						) {
							return <p>{value}</p>;
						} else {
							return <div />;
						}
					},
				},
			},
			{
				name: "rakuten partial",
				label: "Rakuten Partial",
				options: {
					filter: false,
					customBodyRender: (value, tableMeta, updateValue) => {
						if (
							this.checkRakutenEligiblity(
								this.state.table_data[tableMeta.rowIndex]
							)
						) {
							return <p>{value}</p>;
						} else {
							return <div />;
						}
					},
				},
			},
			{
				name: "city",
				label: "City",
				options: {
					filter: false,
					sort: false,
				},
			},
			{
				name: "zipcode",
				label: "Zipcode",
				options: {
					filter: false,
					sort: false,
				},
			},
			{
				name: "amount",
				label: "Amount",
				options: {
					filter: false,
					sort: false,
				},
			},
			{
				name: "edit_order",
				label: "Edit Order",
				options: {
					filter: false,
					customBodyRender: (value, tableMeta, updateValue) => {
						return (
							<Button
								onClick={(e) =>
									this.detailButtonClicked(
										e,
										value,
										tableMeta
									)
								}
								variant="contained"
								color="primary"
							>
								Detail
							</Button>
						);
					},
				},
			},
			{
				name: "rakuten",
				label: "Rakuten",
				options: {
					filter: false,
					customBodyRender: (value, tableMeta, updateValue) => {
						if (
							this.checkRakutenEligiblity(
								this.state.table_data[tableMeta.rowIndex]
							)
						) {
							if (
								this.state.table_data[
									tableMeta.rowIndex
								][6].split(",").length ===
								this.state.table_data[
									tableMeta.rowIndex
								][4].split(",").length &&
								this.state.table_data[tableMeta.rowIndex][7] ===
								"Sent"
							) {
								return (
									<Button
										variant="contained"
										className="sent-button"
										disabled
									>
										Rakuten
									</Button>
								);
							} else if (
								this.state.table_data[
									tableMeta.rowIndex
								][6].split(",").length +
								this.state.table_data[
									tableMeta.rowIndex
								][8].split(",").length -
								1 ===
								this.state.table_data[
									tableMeta.rowIndex
								][4].split(",").length &&
								this.state.table_data[tableMeta.rowIndex][7] ===
								"Sent"
							) {
								return (
									<Button
										variant="contained"
										className="sent-button"
										disabled
									>
										Rakuten
									</Button>
								);
							} else if (
								this.state.table_data[
									tableMeta.rowIndex
								][6].split(",").length ===
								this.state.table_data[
									tableMeta.rowIndex
								][4].split(",").length
							) {
								return (
									<Button
										variant="contained"
										color="primary"
										disabled
									>
										Rakuten
									</Button>
								);
							} else if (
								this.state.table_data[
									tableMeta.rowIndex
								][6].split(",").length +
								this.state.table_data[
									tableMeta.rowIndex
								][8].split(",").length -
								1 ===
								this.state.table_data[
									tableMeta.rowIndex
								][4].split(",").length
							) {
								return (
									<Button
										variant="contained"
										color="primary"
										disabled
									>
										Rakuten
									</Button>
								);
							} else if (
								this.state.table_data[tableMeta.rowIndex][7] ===
								"Not Accepted"
							) {
								return (
									<Button
										variant="contained"
										className="not-accepted-button"
										onClick={(e) =>
											this.rakutenButtonClicked(
												e,
												value,
												tableMeta
											)
										}
									>
										Rakuten
									</Button>
								);
							} else if (
								this.state.table_data[tableMeta.rowIndex][7] ===
								"NOT PLACED" ||
								this.state.table_data[tableMeta.rowIndex][7] ===
								"Infulfillment" ||
								this.state.table_data[tableMeta.rowIndex][7] ===
								"Accepted"
							) {
								return (
									<Button
										onClick={(e) =>
											this.rakutenButtonClicked(
												e,
												value,
												tableMeta
											)
										}
										variant="contained"
										color="primary"
									>
										Rakuten
									</Button>
								);
							} else if (
								this.state.table_data[tableMeta.rowIndex][7] ===
								"Sent"
							) {
								return (
									<Button
										variant="contained"
										className="sent-button"
										disabled
									>
										Rakuten
									</Button>
								);
							} else {
								return (
									<Button
										variant="contained"
										color="primary"
										disabled
									>
										Rakuten
									</Button>
								);
							}
						} else {
							return <div />;
						}
					},
				},
			},
			{
				name: "manual",
				label: "Manual",
				options: {
					filter: false,
					customBodyRender: (value, tableMeta, updateValue) => {
						if (
							this.state.table_data[tableMeta.rowIndex][7] ===
							"Sent" ||
							this.state.table_data[tableMeta.rowIndex][7] ===
							"Backorder"
						) {
							return (
								<Button
									variant="contained"
									className="manual-button"
									disabled
								>
									Manual
								</Button>
							);
						} else if (
							this.state.table_data[tableMeta.rowIndex][6].split(
								","
							).length ===
							this.state.table_data[tableMeta.rowIndex][4].split(
								","
							).length
						) {
							return (
								<Button
									variant="contained"
									className="manual-button"
									disabled
								>
									Manual
								</Button>
							);
						} else if (
							this.state.table_data[tableMeta.rowIndex][6].split(
								","
							).length +
							this.state.table_data[
								tableMeta.rowIndex
							][8].split(",").length -
							1 ===
							this.state.table_data[tableMeta.rowIndex][4].split(
								","
							).length
						) {
							return (
								<Button
									variant="contained"
									className="manual-button"
									disabled
								>
									Manual
								</Button>
							);
						} else {
							return (
								<Button
									onClick={(e) =>
										this.manualButtonClicked(
											e,
											value,
											tableMeta
										)
									}
									variant="contained"
									className="manual-button"
								>
									Manual
								</Button>
							);
						}
					},
				},
			},
		];

		const options = {
			filterType: "checkbox",
			selectableRows: false,
			onRowsDelete: (rowsDeleted) => this.onRowsDelete(rowsDeleted),
			setRowProps: (row) => {
				if (
					(row[7].props.children === "Accepted" ||
						row[7].props.children === "InFulfillment" ||
						row[7].props.children === "Shipped") &&
					moment(row[0]).isBefore(moment("2020-12-13"), "day") ===
					true &&
					moment(row[0]).isAfter(moment("2020-12-01"), "day") === true
				) {
					return {
						style: { backgroundColor: "#ffc0c0" },
					};
				}
			},
			searchOpen: email == null ? false : true,
			searchText: email == null ? "" : email,
		};
		return (
			<div>
				{this.state.loading === false && this.state.hidden == "" ? (
					<div>
						<div className="w-100">
							{" "}
							<button
								className="btn btn-primary"
								style={{
									display: "flex",
									margin: "2rem 2rem 2rem 0",
								}}
								onClick={() =>
									this.setState({
										openTable: !this.state.openTable,
									})
								}
								aria-controls="example-collapse-text"
								aria-expanded={this.state.openTable}
							>
								{this.state.openTable
									? "Hide Inventory"
									: "Show Inventory"}
							</button>
							{/* <div style={{ display: "flex" }}>
								{this.state.openTable ? (
									<div
										aria-controls="example-collapse-text"
										aria-expanded={this.state.openTable}
										onClick={() => {
											this.setState({
												openTable:
													!this.state.openTable,
											});
										}}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="30"
											height="30"
											fill="currentColor"
											class="bi bi-arrow-bar-down"
											viewBox="0 0 16 16"
										>
											<path
												fill-rule="evenodd"
												d="M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z"
											/>
										</svg>
									</div>
								) : (
									<div
										aria-controls="example-collapse-text"
										aria-expanded={this.state.openTable}
										onClick={() => {
											this.setState({
												openTable:
													!this.state.openTable,
											});
										}}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="30"
											height="30"
											fill="currentColor"
											class="bi bi-arrow-bar-up"
											viewBox="0 0 16 16"
										>
											<path
												fill-rule="evenodd"
												d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z"
											/>
										</svg>
									</div>
								)}
							</div>
						 */}
							<Collapse in={this.state.openTable}>
								<Table
									id="example-collapse-text"
									className="w-100 inventory-table"
									hover
								>
									<thead>
										<tr>
											<th>#</th>
											<th>SKU</th>
											<th>Description</th>
											<th>Units in Stock</th>
										</tr>
									</thead>
									<tbody>
										{this.state.rakutenItems.map(
											(item, index) => (
												<tr>
													<td>{index + 1}</td>
													<td>
														{item.stockKeepingUnit}
													</td>
													<td>{item.description}</td>
													<td>
														{item.availableInventories.map(
															(entry, index) => (
																<div className="inventry-row">
																	<div>
																		{
																			entry.warehouseName
																		}
																	</div>
																	:
																	<div>
																		{
																			entry.availableInventoryQuantity
																		}
																	</div>
																</div>
															)
														)}
													</td>
												</tr>
											)
										)}
									</tbody>
								</Table>
							</Collapse>
						</div>
						<PopupManual
							open={this.state.openM}
							handleClose={this.handleCloseManual}
							data={this.state.manualData}
						/>
						<div>
							<Dialog
								open={this.state.openR}
								onClose={this.handleCloseRakuten}
								aria-labelledby="form-dialog-title"
							>
								<DialogTitle
									style={{ marginTop: "2%" }}
									id="form-dialog-title"
								>
									Rakuten Order
								</DialogTitle>
								<DialogContent>
									<DialogContentText>
										Place the Order to Rakuten
									</DialogContentText>
									<h3>Orders:</h3>
									{this.state.shippedOrders.map((order) => (
										<div
											style={{
												display: "flex",
												displayDirection: "row",
											}}
										>
											<Checkbox
												color="primary"
												label={`${order.split(":")[0]}`}
												inputProps={{
													"aria-label": `${order.split(":")[0]
														}`,
												}}
												disabled
											/>
											<p>{order.split(":")[0]}:</p>{" "}
											<TextField
												style={{ marginLeft: "2rem" }}
												autoFocus
												margin="dense"
												id="orders"
												type="name"
												value={order.split(":")[1]}
												disabled
											/>
											<Button
												style={{
													marginLeft: "1rem",
													marginTop: "1rem",
												}}
												color="primary"
												disabled
											>
												Cancel Item
											</Button>
										</div>
									))}
									{this.state.ordersArray.map((order) => (
										<div
											style={{
												display: "flex",
												displayDirection: "row",
											}}
										>
											<Checkbox
												onChange={(e) =>
													this.handleChangeCheckbox(
														e,
														order.split(":")[0]
													)
												}
												value={order.split(":")[0]}
												color="primary"
												label={`${order.split(":")[0]}`}
												inputProps={{
													"aria-label": `${order.split(":")[0]
														}`,
												}}
											/>
											<p>{order.split(":")[0]}:</p>{" "}
											<TextField
												style={{ marginLeft: "2rem" }}
												autoFocus
												margin="dense"
												id="orders"
												type="name"
												value={order.split(":")[1]}
											/>
											<Button
												onClick={(e) =>
													this.onCancelItem(
														e,
														order.split(":")[0]
													)
												}
												style={{
													marginLeft: "1rem",
													marginTop: "1rem",
												}}
												color="primary"
											>
												Cancel Item
											</Button>
										</div>
									))}
								</DialogContent>
								<DialogActions>
									<Button
										onClick={this.handleCloseRakuten}
										color="primary"
									>
										Close
									</Button>
									<Button
										onClick={(e) => this.createOrder()}
										color="primary"
									>
										Submit
									</Button>
								</DialogActions>
							</Dialog>
						</div>
						<Popup
							open={this.state.open}
							data={this.state.popupData}
							onCancelOrder={this.onCancelOrder}
						/>
						<MUIDataTable
							title={"Web Orders"}
							data={this.state.table_data}
							columns={columns}
							className={this.state.hidden}
							options={options}
						/>
					</div>
				) : (
					<Loading />
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	webOrders: state.webOrders.webOrders,
	rakutenItems: state.webOrders.rakutenItems,
});

const mapDispatchToProps = (dispatch) => {
	return {
		getWebOrders: () => {
			dispatch(getWebOrders);
		},
		getRakutenItems: (authToken) => {
			getRakutenItems(dispatch, authToken);
		},
		getRakutenOrders: (authToken) => {
			getRakutenOrders(dispatch, authToken);
		},
		cancelOrder: (orderId) => {
			cancelOrder(dispatch, orderId);
		},
		cancelItem: (orderId) => {
			cancelItem(dispatch, orderId);
		},
		updateUserDetails: (orderId) => {
			updateUserDetails(dispatch, orderId);
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(DataTable));
