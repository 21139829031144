import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";

import { set } from "lodash";
import { firebase } from "../firebase";
import { cancelOrder, cancelItem, updateUserDetails, updateShipmentLink } from "../actions/orderActions";
import { connect } from "react-redux";
import { render } from "@testing-library/react";
const db = firebase.firestore();
const _ = require("lodash");
const axios = require("axios");
const moment = require("moment");

class PopupRakuten extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      fieldUpdated: false,
      quantity: 1,
      token: "",
      authToken: "",
    };
  }

  componentDidMount() {
    this.getAuthToken();
  }

  componentWillReceiveProps(nextProps) {
    console.log("USE EFECT CALLED");
    this.setState({ open: nextProps.open });
  }

  handleClose = (e) => {
    e.preventDefault();
    this.setState({ open: false });
  };

  onChangeQuantity = (e) => {
    e.preventDefault();
    this.setState({ quantity: e.target.value });
  };
  onUpdateDetails = (e) => {
    e.preventDefault();
    // this.createOrder();
    this.setState({ open: false });
  };
  // createOrder = () => {
  //   console.log("TIME STAMP REF NUM", (+new Date()).toString(36));
  //   fetch(`https://api.shipnetwork.com/Orders`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Access-Control-Allow-Origin": "*",
  //       Authorization: `Bearer ${this.state.authToken}`,
  //       ClientId: "5004",
  //     },
  //     body: JSON.stringify({
  //       referenceNumber: (+new Date()).toString(36),
  //       orderType: "Consumer",
  //       customerFullName: "John Smith",
  //       customerStreetAddress1: "Mulholland Dr. 1",
  //       customerCity: "Santa Monica",
  //       customerStateOrProvince: "CA",
  //       customerPostalCode: "23596",
  //       customerCountry: "US",
  //       customerEmailAddress: "john.smith@testinc.com",
  //       customerTelephoneNumber: "55589652356",
  //       orderSubTotalAmount: 50.0,
  //       orderTaxAmount: 0,
  //       orderShippingCostAmount: 0,
  //       orderTotalAmount: 50.0,
  //       stockKeepingUnits: [
  //         {
  //           stockKeepingUnit: "shifu014-SMC",
  //           quantityOrdered: 1,
  //           unitPrice: 50.0,
  //         },
  //       ],
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((result) => {
  //       // this.setState({ authToken: result.data.token });
  //       console.log("FETCH RESPONSE", result);
  //     })
  //     .catch((err) => console.log("FETCH ERROR", err));
  // };
  getAuthToken = () => {
    fetch(`https://api.shipnetwork.com/Auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        clientId: "5004",
        apiUserIdentifier: "455575fb-86a9-413d-ade3-ebf2a9d527f2",
        apiUserSecret:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NDU3NjkxNzMsImlzcyI6IlJha3V0ZW5TdXBlckxvZ2lzdGljcy5TZWN1cml0eSIsImF1ZCI6IlJha3V0ZW5TdXBlckxvZ2lzdGljcy5TZWN1cml0eSJ9.j5gCrUqdh8pddCQR7LRb1fvk9oeNH5aY_WrFsX2csmM",
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({ authToken: result.data.token });
        console.log("FETCH RESPONSE", result);
      })
      .catch((err) => console.log("FETCH ERROR", err));
  };

  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle style={{ marginTop: "2%" }} id="form-dialog-title">
            Create Order{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Select SKU and Quantity to create order</DialogContentText>
            <TextField autoFocus margin="dense" id="email" label="SKU" type="email" value="Orboot" fullWidth />
            <TextField
              autoFocus
              onChange={(e) => this.onChangeQuantity(e)}
              margin="dense"
              id="name"
              label="Quantity"
              type="name"
              value={this.state.quantity}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="primary">
              Close
            </Button>
            <Button onClick={(e) => this.onUpdateDetails(e)} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  webOrders: state.webOrders.webOrders,
});
const mapDispatchToProps = (dispatch) => {
  return {
    cancelItem: (orderId, sku) => {
      cancelItem(dispatch, orderId, sku);
    },
    updateUserDetails: (details, orderId) => {
      updateUserDetails(dispatch, details, orderId);
    },
    updateShipmentLink: (link, sku, orderId) => {
      updateShipmentLink(dispatch, link, sku, orderId);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupRakuten);
