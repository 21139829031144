import React, { Component } from "react";
import DataTableRakuten from "./Components/DataTableRakuten";
export default class Rakuten extends Component {
  render() {
    return (
      <div style={{ padding: "2rem" }}>
        <DataTableRakuten />
      </div>
    );
  }
}
