import { GET_WEB_ORDERS, GET_RAKUTEN_ITEMS, GET_RAKUTEN_ORDERS } from "../actions/types";

const initialState = {
  webOrders: [],
  rakutenItems: [],
  rakutenOrders: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_WEB_ORDERS:
      return {
        ...state,
        webOrders: action.payload,
      };
    case GET_RAKUTEN_ITEMS:
      return {
        ...state,
        rakutenItems: action.payload,
      };
    case GET_RAKUTEN_ORDERS:
      return {
        ...state,
        rakutenOrders: action.payload,
      };
    default:
      return state;
  }
}
