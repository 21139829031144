import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { firebase } from "../../../firebase";
import { getRakutenOrders } from "../../../actions/getDataAction";
import { cancelOrder, cancelItem, updateUserDetails } from "../../../actions/orderActions";
import PopupRakuten from "../../../Components/PopupRakuten";
import Button from "@material-ui/core/Button";

const _ = require("lodash");
const moment = require("moment");

const db = firebase.firestore();

class DataTableRakuten extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table_data: [],
      open: false,
      popupData: [[]],
    };
  }

  componentWillMount() {
    // this.props.getRakutenOrders();
    this.setState({ table_data: this.props.rakutenOrders });
  }

  componentWillReceiveProps(nextProps) {
    console.log("PROPS FOR THIS PAGE", nextProps);
    this.setState({ table_data: nextProps.rakutenOrders });
  }

  onCancelOrder = () => {
    this.setState({ open: false });
  };

  render() {
    const columns = [
      {
        name: "RSL Order",
        label: "RSL Order",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Ref. No.",
        label: "Ref. No.",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "Customer Name",
        label: "Customer Name",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "Created On",
        label: "Created On",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "Items",
        label: "Items",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "Warehouse",
        label: "Warehouse",
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: "status",
        label: "Status",
        options: {
          filter: true,
          sort: false,
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: false,
      onRowsDelete: (rowsDeleted) => this.onRowsDelete(rowsDeleted),
    };
    return (
      <div>
        {/* <PopupRakuten open={this.state.open} data={this.state.popupData} /> */}
        <MUIDataTable title={"Rakuten Orders"} data={this.state.table_data} columns={columns} options={options} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  webOrders: state.webOrders.webOrders,
  rakutenOrders: state.webOrders.rakutenOrders,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getRakutenOrders: () => {
      dispatch(getRakutenOrders);
    },
    cancelOrder: (orderId) => {
      cancelOrder(dispatch, orderId);
    },
    cancelItem: (orderId) => {
      cancelItem(dispatch, orderId);
    },
    updateUserDetails: (orderId) => {
      updateUserDetails(dispatch, orderId);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataTableRakuten));
