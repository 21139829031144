import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";

import { set } from "lodash";
import { firebase } from "../firebase";
import {
  cancelOrder,
  cancelItem,
  updateUserDetails,
  updateShipmentLink,
} from "../actions/orderActions";
import { connect } from "react-redux";
import { render } from "@testing-library/react";
const db = firebase.firestore();
const _ = require("lodash");
const uuidv4 = require("uuid/v4");
const moment = require("moment");
class PopupRakutenButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ordersArray: [],
      shippedOrders: [],
      open: false,
      shipmentLink: "",
      selectedSku: [],
      loader: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    console.log("USE EFECT CALLED RAKUTEN", nextProps.open);

    this.setState({
      open: nextProps.open,
    });
     
    // if (
    //  nextProps.data[0][4] &&
    //   nextProps.data[0][4] !== "" &&
    //   nextProps.data[0][6] === "Unshipped"
    // ) {
    //   let orderArray = nextProps.data[0][4].split(",");
    //   orderArray = orderArray.filter(function (el) {
    //     return el != "";
    //   });
    //   let ordersArray = [];
    //   orderArray.map((order) => {
    //     ordersArray.push(order);
    //   });
    //   console.log("ORDER ARRAY", ordersArray);
    //   this.setState({ ordersArray: ordersArray, shippedOrders: [] });
    // } else if (
    //   nextProps.data[0][6] &&
    //   nextProps.data[0][6] !== "" &&
    //   nextProps.data[0][6] !== "Unshipped"
    // ) {
    //   let orderArray = nextProps.data[0][4].split(",");
    //   orderArray = orderArray.filter(function (el) {
    //     return el != "";
    //   });
    //   let ordersArray = [];
    //   let splitOrders = [];
    //   orderArray.map((order) => {
    //     ordersArray.push(order);
    //     order = order.split(":")[0];
    //     splitOrders.push(order);
    //   });

    //   let shippedOrder = nextProps.data[0][6].split(",");
    //   shippedOrder = shippedOrder.filter(function (el) {
    //     return el != "";
    //   });
    //   let shippedOrders = [];
    //   let splitShipped = [];
    //   shippedOrder.map((order) => {
    //     shippedOrders.push(order);
    //     order = order.split(":")[0];
    //     splitShipped.push(order);
    //   });
    //   splitOrders = splitOrders.filter((val) => !splitShipped.includes(val));
    //   console.log(
    //     "UNSHIPPED",
    //     splitOrders,
    //     ordersArray,
    //     shippedOrders,
    //     splitShipped
    //   );
    //   if (splitOrders.length === 0) {
    //     this.setState({ shippedOrders: ordersArray, ordersArray: [] });
    //   } else {
    //     let finalShipped = [];
    //     let finalUnshipped = [];
    //     splitOrders.forEach((order) => {
    //       orderArray.forEach((ord) => {
    //         if (order === ord.split(":")[0]) {
    //           finalUnshipped.push(ord);
    //         }
    //       });
    //     });
    //     ordersArray.forEach((order) => {
    //       splitShipped.forEach((ord) => {
    //         if (order.includes(ord)) {
    //           finalShipped.push(order);
    //         }
    //       });
    //     });
    //     console.log(finalShipped, finalUnshipped);
    //     this.setState({
    //       ordersArray: finalUnshipped,
    //       shippedOrders: finalShipped,
    //     });
    //   }
    // } else {
    //   console.log("RANDOM MESSAGE");
    // }
  }

  handleClose = () => {
    this.setState({ open: false });
    this.props.handleClose();
  };

  onChangeTrackingLink = (e) => {
    e.preventDefault();
    this.setState({ shipmentLink: e.target.value });
  };

  updateShipmentLink = (e) => {
    // this.setState({ loader : true })
    e.preventDefault();
    let ship_qty = [];
    let sku_array = this.props.data[0][4].split(",");
    console.log("ORDER ID", this.state.shipmentLink);
    sku_array.forEach((element) => {
      this.state.selectedSku.forEach((sku) => {
        console.log(element, sku);
        if (element.includes(sku)) {
          ship_qty.push(parseInt(element.split(":")[1]));
        }
      });
    });
    let id = uuidv4().replace(/-/g, "");
    let order_id = this.props.data[0][13];
    let ship_item = this.state.selectedSku;
    let link = this.state.shipmentLink;
    let created_at = moment().format("YYYY-MM-DD HH:mm:ss");
    let is_cancel = 0;
    let name = this.props.data[0][1];
    let email = this.props.data[0][2];

    console.log(
      id,
      order_id,
      ship_item,
      ship_qty,
      link,
      created_at,
      is_cancel,
      name,
      email
    );
    this.props.updateShipmentLink(
      id,
      order_id,
      ship_item,
      ship_qty,
      link,
      created_at,
      is_cancel,
      name,
      email
    );
    this.setState({ open: false });
    this.props.handleClose();
    this.setState({ loader: false });
  };
  handleChangeCheckbox = (e, sku) => {
    let skuArray = [...this.state.selectedSku, e.target.value];
    if (this.state.selectedSku.includes(e.target.value)) {
      skuArray = skuArray.filter((sku) => sku !== e.target.value);
    }
    this.setState({ selectedSku: skuArray });
    console.log(this.state.selectedSku);
  };
  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle style={{ marginTop: "2%" }} id="form-dialog-title">
            Rakuten Order
          </DialogTitle>
          {this.state.loader === true ? (
            <div>
              <img
                style={{ height: "200px", marginTop: "175px" }}
                src="https://flevix.com/wp-content/uploads/2020/01/Preloader.gif"
              />
              <p style={{ marginTop: "-50px" }}>
                Please do not refresh while the CSV is being uploaded
              </p>
            </div>
          ) : (
            <DialogContent>
              <DialogContentText>Place the Order to Rakuten</DialogContentText>
              <h3>Orders:</h3>
              {this.state.shippedOrders.map((order) => (
                <div style={{ display: "flex", displayDirection: "row" }}>
                  <Checkbox
                    color="primary"
                    label={`${order.split(":")[0]}`}
                    inputProps={{ "aria-label": `${order.split(":")[0]}` }}
                    disabled
                  />
                  <p>{order.split(":")[0]}:</p>{" "}
                  <TextField
                    style={{ marginLeft: "2rem" }}
                    autoFocus
                    margin="dense"
                    id="orders"
                    type="name"
                    value={order.split(":")[1]}
                    disabled
                  />
                  <Button
                    style={{ marginLeft: "1rem", marginTop: "1rem" }}
                    color="primary"
                    disabled
                  >
                    Cancel Item
                  </Button>
                </div>
              ))}
              {this.state.ordersArray.map((order) => (
                <div style={{ display: "flex", displayDirection: "row" }}>
                  <Checkbox
                    onChange={(e) =>
                      this.handleChangeCheckbox(e, order.split(":")[0])
                    }
                    value={order.split(":")[0]}
                    color="primary"
                    label={`${order.split(":")[0]}`}
                    inputProps={{ "aria-label": `${order.split(":")[0]}` }}
                  />
                  <p>{order.split(":")[0]}:</p>{" "}
                  <TextField
                    style={{ marginLeft: "2rem" }}
                    autoFocus
                    margin="dense"
                    id="orders"
                    type="name"
                    value={order.split(":")[1]}
                  />
                  <Button
                    onClick={(e) => this.onCancelItem(e, order.split(":")[0])}
                    style={{ marginLeft: "1rem", marginTop: "1rem" }}
                    color="primary"
                  >
                    Cancel Item
                  </Button>
                </div>
              ))}
              <div style={{ display: "flex", displayDirection: "row" }}>
                <p>Tracking Link:</p>{" "}
                <TextField
                  style={{ marginLeft: "2rem" }}
                  autoFocus
                  margin="dense"
                  id="orders"
                  onChange={(e) => this.onChangeTrackingLink(e)}
                  type="name"
                  value={this.state.shipmentLink}
                />
                <Button
                  onClick={(e) => this.updateShipmentLink(e)}
                  style={{ marginLeft: "1rem", marginTop: "1rem" }}
                  color="primary"
                >
                  Submit
                </Button>
              </div>
            </DialogContent>
          )}

          <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    cancelItem: (orderId, sku) => {
      cancelItem(dispatch, orderId, sku);
    },
    updateUserDetails: (details, orderId) => {
      updateUserDetails(dispatch, details, orderId);
    },
    updateShipmentLink: (
      id,
      order_id,
      ship_item,
      ship_qty,
      link,
      created_at,
      is_cancel,
      name,
      email
    ) => {
      updateShipmentLink(
        dispatch,
        id,
        order_id,
        ship_item,
        ship_qty,
        link,
        created_at,
        is_cancel,
        name,
        email
      );
    },
  };
};

export default connect( mapDispatchToProps)(PopupRakutenButton);
