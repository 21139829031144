import { firebase } from "../firebase";
const axios = require("axios");
const db = firebase.firestore();
export const cancelOrder = (dispatch, orderId) => {
  fetch(
    `https://us-central1-mws-sales-data.cloudfunctions.net/DeleteWebOrdersData?orderId=${orderId}`
  )
    .then((res) => {
      console.log("RESPONSE FB CF", res);
    })
    .catch((err) => console.log("ERROR FETCH FB CF", err));
};
export const cancelItem = (dispatch, sku, orderId) => {
  fetch(
    `https://us-central1-mws-sales-data.cloudfunctions.net/CancelWebOrdersItem?sku=${sku}&orderId=${orderId}`
  )
    .then((res) => {
      console.log("RESPONSE FB CF", res);
    })
    .catch((err) => console.log("ERROR FETCH FB CF", err));
};
export const updateUserDetails = (dispatch, details, orderId) => {
  console.log("ACTION ORDER", details, orderId);
  fetch(
    `https://us-central1-mws-sales-data.cloudfunctions.net/updateUserDetails?details=${details}&orderId=${orderId}`
  )
    .then((res) => {
      console.log("RESPONSE FB CF", res);
    })
    .catch((err) => console.log("ERROR FETCH FB CF", err));
};
export const updateShipmentLink = async (
  dispatch,
  id,
  order_id,
  ship_item,
  ship_qty,
  link,
  created_at,
  is_cancel,
  name,
  email
) => {
  let shipment_object = {
    id: id,
    order_id: order_id,
    ship_item: ship_item,
    ship_qty: ship_qty,
    link: link,
    created_at: created_at,
    is_cancel: is_cancel,
  };
  console.log("ACTION ORDER SHIPMENT ", shipment_object);

  // fetch(
  //   `https://us-central1-mws-sales-data.cloudfunctions.net/updateShipmentLink-1?data=${encodeURIComponent(
  //     JSON.stringify(shipment_object)
  //   )}`
  // )
  //   .then((response) => response.json())
  //   .then((res) => {
  //     console.log("RESPONSE FB CF", res);
  //   })
  //   .catch((err) => console.log("ERROR FETCH FB CF", err));
  fetch(
    `https://us-central1-mws-sales-data.cloudfunctions.net/opsDashSendEmailToUser?data=${encodeURIComponent(
      JSON.stringify({
        sku: shipment_object.ship_item,
        quantity: shipment_object.ship_qty,
        orderId: shipment_object.order_id,
        link: shipment_object.link,
        name: name,
        email: email,
      })
    )}`
  )
    .then((response) => console.log("res", response.json()))
    .then(async (err, res) => {
      if (err) {
        alert("Something went wrong, please refresh the page and try again");
      }
      console.log("RESPONSE FB CF", res);
      await db
        .collection("web-orders")
        .doc(shipment_object.order_id)
        .get()
        .then(async(doc) => {
          let data = doc.data();

          data.sku.forEach((sku) => {
            shipment_object.ship_item.forEach((item) => {
              console.log(sku.sku, item, "LINK CHECK");
              if (sku.sku === item) {
                sku.link = shipment_object.link;
              }
            });
          });
          // data.sku.forEach((element) => {
          //   if (element.sku === shipment_object.ship_item) {
          //     element.link = shipment_object.link;
          //   }
          // });
          console.log("DATA TO POST IN FIREBASE", data);
          await db.collection("web-orders").doc(shipment_object.order_id).set(data);
        });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    })
    .catch((err) =>
      alert("Something went wrong, please reload the page and try again")
    );
};
