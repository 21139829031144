import { GET_WEB_ORDERS, GET_RAKUTEN_ITEMS, GET_RAKUTEN_ORDERS } from "./types";
import { firebase } from "../firebase";
var CryptoJS = require("crypto-js");
var AES = require("crypto-js/aes");
var utf8 = require("crypto-js/enc-utf8");

const _ = require("lodash");
const moment = require("moment");

const db = firebase.firestore();
export const getWebOrders = (dispatch) => {
  let data = [];
  const startOfYear = moment().date(1).month(11).subtract(1, "year").toDate()
  console.log(startOfYear)
  db.collection("web-orders").onSnapshot((querySnapshot) => {
    let count = 1;
    querySnapshot.forEach((doc) => {
      let sku_string = "";
      let shipped_status = "";
      let rakuten_partial = ""
      let link = doc.data().sku[0].link
      // if (!Array.isArray(doc.data().sku)) console.log("CURSED SKU", doc.data());
      doc.data().sku.forEach((item) => {
        if (!item.is_cancel) sku_string += `${item.sku}:${item.quantity},`;
        if (item.status === "Shipped" && item.link) shipped_status += `${item.sku}:Shipped,`;  // Added by anand
        if (!item.status && item.link) shipped_status += `${item.sku}:Shipped,`;
      });
      doc.data().sku.forEach((item) => {
        if (!item.link && item.status === "Sent") rakuten_partial += `${item.sku}:Sent,`
        if (item.status === "Accepted") rakuten_partial += `${item.sku}:Accepted,`
        if (item.status === "InFulfillment") rakuten_partial += `${item.sku}:InFulfillment,`
        if (item.status === "Backorder" && !item.link) rakuten_partial += `${item.sku}:Backorder,`
        if (item.link && item.status) rakuten_partial += `${item.sku}:Shipped,`

      });


      let data_object = {
        name: `${doc.data().name}` || "",
        country: `${doc.data().country}` || "",
        sku: sku_string,
        city: `${doc.data().city}` || "",
        amount:
          `$${doc.data().country === "IN"
            ? Math.round((doc.data().amount / 74).toFixed(0))
            : Math.round(doc.data().amount)
          }` || "",
        id: count,
        date: doc.data().date,
        address: doc.data().address,
        is_cancel: doc.data().is_cancel,
        email: doc.data().email,
        payType: doc.data().payType || "null",
        shipped_status: shipped_status === "" ? "Unshipped" : shipped_status,
        rakuten_status: doc.data().rakuten_status || "NOT PLACED",
        rakuten_partial: rakuten_partial === "" ? "Unshipped" : rakuten_partial,
        orderId: doc.data().orderId,
        zipcode: doc.data().zipcode,
        state: doc.data().state,
        contact_number: doc.data().contact_number,
        count: count,
        link: link
      };
      count += 1;
      // console.log(data_object);
      let emailValidation = validateEmail(data_object.email);
      if (emailValidation === false) {
        let emailText = CryptoJS.AES.decrypt(
          data_object.email,
          "secret_key_123"
        ).toString(CryptoJS.enc.Utf8);
        let contactNumberText = CryptoJS.AES.decrypt(
          data_object.contact_number,
          "secret_key_123"
        ).toString(CryptoJS.enc.Utf8);

        data_object.email = emailText;
        data_object.contact_number =
          contactNumberText !== "" ? contactNumberText : "+1-650-485-1701";
      }

      if (data_object.is_cancel != 1) data.push(data_object);
    });
    data = _.sortBy(data, function (o) {
      return new moment(o.date);
    }).reverse();

    data = _.uniqBy(data, "orderId");
    let temp_data = [];

    data.forEach((doc) => {
      let order_array = [
        moment(doc.date).format("YYYY-MM-DD"),
        doc.name,
        doc.email,
        doc.country,
        doc.sku,
        doc.payType,
        doc.shipped_status,
        doc.rakuten_status,
        doc.rakuten_partial,
        doc.city,
        doc.zipcode,
        doc.amount,
        doc.contact_number,
        doc.address,
        doc.orderId,
        doc.state,
        doc.link
      ];

      if (doc.name !== "undefined") {
        temp_data.push(order_array);
      }
    });
    // console.log("TEMP DATA", temp_data);
    dispatch({
      type: GET_WEB_ORDERS,
      payload: temp_data,
    });
  });
};

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const getRakutenItems = (dispatch, authToken) => {
  let rakuten_items = [];
  for (let i = 1; i < 3; i++) {
    fetch(`https://api.shipnetwork.com/Items?Page=${i}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${authToken}`,
        ClientId: "5004",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        // console.log("RESULT", result.data);
        result.data.forEach((entry) => rakuten_items.push(entry));
        if (i === 2) {
          dispatch({ type: GET_RAKUTEN_ITEMS, payload: rakuten_items });
        }
      })
      .catch((err) => console.log("FETCH ERROR", err));
  }
};

export const getRakutenOrders = (dispatch, authToken) => {
  let rakuten_orders = [];
  db.collection("rakuten-orders")
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        rakuten_orders.push(doc.data());
      });

      rakuten_orders = _.sortBy(rakuten_orders, "dateCreated");
      // console.log("rakuten_orders", rakuten_orders);

      let rakuten_table_array = [];

      rakuten_orders.forEach((entry) => {
        let order_array = [
          entry.orderId,
          entry.referenceNumber,
          entry.orderCustomerName,
          moment(entry.dateCreated).format("YYYY-MM-DD"),
          entry.stockKeepingUnits.length,
          entry.warehouseName,
          entry.statusDescription,
        ];

        rakuten_table_array.push(order_array);
      });

      rakuten_table_array.reverse();
      dispatch({ type: GET_RAKUTEN_ORDERS, payload: rakuten_table_array });
    });
};
