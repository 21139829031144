import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "./Pages/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Rakuten from "./Pages/Dashboard/Rakuten";
import Sidebar from "./Pages/Sidebar/Sidebar";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
	return (
		<div className="App">
			<header className="App-header"></header>
			<BrowserRouter>
				<Switch>
					<Route
						exact
						path="/"
						render={() => <Redirect to="/login" />}
					/>
					<Route path="/login">
						<Login />
					</Route>
					<Route path="/dashboard">
						<Dashboard />
					</Route>
					<Route path="/rakuten">
						<Rakuten />
					</Route>
					<Route path="/sidebar">
						<Sidebar />
					</Route>
				</Switch>
			</BrowserRouter>
		</div>
	);
}

export default App;
